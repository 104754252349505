<template>
    <a-modal
        title="通知公告详情"
        :width="1000"
        :confirmLoading="confirmLoading"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
    >
        <a-spin :spinning="confirmLoading">
            <div class="public-title">{{ this.contentRecord.title }}</div>
            <br />
            <div class="publicUserInfo">
                <span>（发布人：{{ this.contentRecord.publicUserName }}）</span>
                <span>发布时间：{{ this.contentRecord.publicTime }} </span>
            </div>
            <a-divider class="a-divider" />
            <div>
                <label v-html="this.contentRecord.content"></label>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { sysNoticeDetail } from '@/api/modular/system/noticeManage'

export default {
    name: 'DetailForm',
    components: {},

    data() {
        return {
            visible: false,
            confirmLoading: false,
            contentRecord: '',
        }
    },

    methods: {
        // 初始化方法
        detail(record) {
            this.confirmLoading = true
            this.visible = true
            this.sysNoticeDetail(record.id)
        },

        /**
         * 查看详情
         */
        sysNoticeDetail(id) {
            sysNoticeDetail({ id: id }).then((res) => {
                this.confirmLoading = false
                this.contentRecord = res.data
            })
        },

        handleCancel() {
            this.visible = false
        },
    },
}
</script>
<style>
.subButton {
    float: right;
}
.subForm-item {
    margin-bottom: 0px;
}
.a-divider {
    margin-top: 5px;
}
.publicUserInfo {
    font-size: 10px;
    text-align: right;
}
.public-title {
    font-size: 30px;
    text-align: center;
}
</style>
